<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <mdb-container v-if="!isloading">
              <mdb-row>
                <mdb-col md="6">
                  <h1>{{$t('holasun.passenger_of_flight')}} {{flightinfos.flightnum}}.</h1> <h2>{{$t('holasun.departure')}} {{getDepartureDate()}} {{getDepartureTime()}}</h2>
                  <h3>{{getOriginGateway()}} <mdb-icon icon="plane" /> {{getDestinationGateway()}}</h3>
                </mdb-col>
                <mdb-col md="6">
                  <h2>Infos</h2>
                  <mdb-tbl btn striped responsive>
                    <mdb-tbl-body>
                      <tr>
                        <td>Current Nb. Paxes</td>
                        <td>{{currentpaxs.length}}</td>
                      </tr>
                      <tr v-if="rawcsvcontent && !error">
                        <td>Nb paxes to be added</td>
                        <td>{{nbPaxToAdd}}</td>
                      </tr>
                      <tr v-if="rawcsvcontent && !error">
                        <td>Nb paxes to be deleted</td>
                        <td>{{nbPaxToDelete}}</td>
                      </tr>
                    </mdb-tbl-body>
                  </mdb-tbl>
                </mdb-col>
              </mdb-row>
              <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
              <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            </mdb-container>
            <div class="d-flex justify-content-center" v-if="isloading">
              <mdb-spinner big crazy multicolor />
            </div>
            <div v-else>
              <mdb-row class="justify-content-end pb-4">
                <mdb-col class="text-right" md="6" v-if="manualuploadactive">
                  <mdb-icon icon="check" style="float:left;" class="pt-4 pr-2 green-text" size="2x" v-if="rawcsvcontent" /><mdb-file-input @getValue="getFileInputValue" outline="default" icon="upload" icon-right class="text-right" :btnTitle="$t('holasun.passengers_manifest')" :textFieldTitle="$t('holasun.csv_file')" accept=".csv" />
                  <mdb-icon icon="check" style="float:left;" class="pt-2 pr-2 green-text" size="2x" v-if="rawcsvcontentaddon.length" /><mdb-file-input @getValue="getFileInputValueForAddon" outline="default" icon="upload" icon-right class="text-right" :btnTitle="$t('holasun.passengers_manifest_addon')" :textFieldTitle="$t('holasun.csv_file')" multiple accept=".csv" />
                </mdb-col>
              </mdb-row>
              <mdb-btn color="dark-green" v-on:click="savecsv" v-if="showsavebtn && manualuploadactive">{{$t('holasun.save')}}</mdb-btn>
              <mdb-tbl btn striped responsive v-if="false">
                <mdb-tbl-head>
                  <tr>
                    <th>Status</th>
                    <th>{{$t('holasun.complete_name')}}</th>
                    <th>{{$t('holasun.birthdate')}}</th>
                    <th>{{$t('holasun.telephone')}}</th>
                    <th>{{$t('holasun.booking')}}</th>
                    <th>SSR</th>
                    <th>Select</th>
                    <th>IFE</th>
                    <th>additionalbag</th>
                    <th>owtoszbag</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr v-for="pax in paxslisttodisplay" v-bind:key="pax.id" :class="getRowColor(pax.status)">
                    <td><strong>{{pax.status}}</strong></td>
                    <td>{{pax.title}} {{pax.firstname}} {{pax.middlename}} {{pax.lastname}}</td>
                    <td>{{pax.birthdate}}</td>
                    <td>{{pax.telephone}}</td>
                    <td>{{pax.bookingnumber}}</td>
                    <td>{{pax.ssr}}</td>
                    <td>{{pax.select}}</td>
                    <td>{{pax.ife}}</td>
                    <td>{{pax.adtlbags}}</td>
                    <td>{{pax.osowbag}}</td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
              <mdb-datatable-2 v-model='paxToDisplayforDataTable' responsiveMd />
            </div>
            <h1 v-if="!paxslisttodisplay.length">{{$t('holasun.no_passenger')}}</h1>
          </mdb-container>
          </div>
        <mdb-btn color="dark-green" v-on:click="savecsv" v-if="showsavebtn">{{$t('holasun.save')}}</mdb-btn>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbFileInput,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbIcon,
  mdbSpinner,
  mdbDatatable2,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'holasunflightdetails',
  data() {
    return {
      error: null,
      success: null,
      isloading: true,
      currentpaxs: [],
      newlistpaxs: [],
      paxslisttodisplay: [],
      rawcsvcontent: '',
      rawcsvcontentaddon: [],
      showsavebtn: false,
      manualuploadactive: false,
      flightinfos: {
        flightnum: '',
        depdate: '',
      },
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbAlert,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbFileInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
    mdbSpinner,
    mdbDatatable2,
  },
  mounted() {
    this.loadPaxes();
  },
  computed: {
    nbPaxToAdd() {
      let nb = 0;
      for (let i = 0; i < this.newlistpaxs.length; i += 1) {
        if (this.newlistpaxs[i].status === 'NEW') {
          nb += 1;
        }
      }
      return nb;
    },
    nbPaxToDelete() {
      let nb = 0;
      for (let i = 0; i < this.newlistpaxs.length; i += 1) {
        if (this.newlistpaxs[i].status === 'DELETED') {
          nb += 1;
        }
      }
      return nb;
    },
    paxToDisplayforDataTable() {
      const thedata = {
        columns: [
          {
            label: 'Status',
            field: 'status',
            sort: true,
          },
          {
            label: 'Fullname',
            field: 'fullname',
            sort: true,
          },
          {
            label: 'DOB',
            field: 'birthdate',
            sort: true,
          },
          {
            label: 'Phone',
            field: 'telephone',
            sort: true,
          },
          {
            label: '# booking',
            field: 'bookingnumber',
            sort: true,
          },
          {
            label: 'SSR',
            field: 'ssr',
            sort: true,
          },
          {
            label: 'Select',
            field: 'select',
            sort: true,
          },
          {
            label: 'IFE',
            field: 'ife',
            sort: true,
          },
          {
            label: 'Adtlbags',
            field: 'adtlbags',
            sort: true,
          },
          {
            label: 'Osowbag',
            field: 'osowbag',
            sort: true,
          },
          {
            label: 'Bike',
            field: 'bike',
            sort: true,
          },
          {
            label: 'Dive',
            field: 'dive',
            sort: true,
          },
          {
            label: 'Golf',
            field: 'golf',
            sort: true,
          },
          {
            label: 'Kite',
            field: 'kite',
            sort: true,
          },
        ],
        rows: [],
      };
      for (let i = 0; i < this.paxslisttodisplay.length; i += 1) {
        const tmp = this.paxslisttodisplay[i];
        tmp.fullname = `${tmp.firstname} ${tmp.middlename || ''} ${tmp.lastname}`;
        thedata.rows.push(tmp);
      }
      return thedata;
    },
  },
  methods: {
    loadPaxes() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/holasun/getpaxesforflight', { id: Number(this.$route.params.id) }).then((response) => {
        if (response.status === 'OK') {
          self.currentpaxs = response.data.paxes;
          self.flightinfos = response.data.flightinfos;
          self.paxslisttodisplay = self.currentpaxs;
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
    getOriginGateway() {
      return this.flightinfos.segments[0].departure_gateway.code;
    },
    getDestinationGateway() {
      return this.flightinfos.segments.slice(-1)[0].arrival_gateway.code;
    },
    getDepartureDate() {
      const thedate = new Date(this.flightinfos.segments[0].departure_datetime);
      thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getDepartureTime() {
      const thedate = new Date(this.flightinfos.segments[0].departure_datetime.replace('T', ' ').replace('Z', ''));
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleTimeString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getFileInputValue(thefile) {
      const self = this;
      if (thefile.length > 0) {
        const reader = new FileReader();
        reader.readAsText(thefile[0]);
        reader.onload = function (evt) {
          self.rawcsvcontent = evt.target.result;
          self.validatecsvfile();
        };
      } else {
        self.rawcsvcontent = null;
        self.error = this.$t('holasun.select_csv_file');
      }
    },
    getFileInputValueForAddon(thefile) {
      const self = this;
      if (thefile.length > 0) {
        for (let i = 0; i < thefile.length; i += 1) {
          const reader = new FileReader();
          reader.readAsText(thefile[i]);
          reader.onload = function (evt) {
            self.rawcsvcontentaddon.push(evt.target.result);
            if (self.rawcsvcontentaddon.length === thefile.length) {
              self.validatecsvfile();
            }
          };
        }
      } else {
        self.rawcsvcontent = null;
        self.error = this.$t('holasun.select_csv_file');
      }
    },
    validatecsvfile() {
      const self = this;
      const api = new Apicall();
      self.error = null;
      self.isloading = true;
      self.showsavebtn = false;
      api.call('POST', 'api/holasun/validatecsvfile', { id: this.$route.params.id, csvcontent: this.rawcsvcontent, csvaddoncontent: this.rawcsvcontentaddon }).then((response) => {
        if (response.status === 'OK') {
          self.newlistpaxs = response.data;
          self.paxslisttodisplay = self.newlistpaxs;
          let canshowbtn = true;
          for (let i = 0; i < self.newlistpaxs.length; i += 1) {
            if (self.newlistpaxs[i].status === 'ERROR_INVALID_BIRTHDATE') {
              canshowbtn = false;
              self.error = 'At least one passenger has an invalid birthdate';
            }
            if (self.newlistpaxs[i].status === 'ERROR_RETURN_FLIGHT_NOT_FOUND') {
              canshowbtn = false;
              self.error = 'At least one passenger as a return flight not found. Please check the csv file.';
            }
          }
          self.showsavebtn = canshowbtn;
        } else {
          self.error = response.msg;
          self.showsavebtn = false;
        }
        self.isloading = false;
      });
    },
    getRowColor(status) {
      let classname = '';
      switch (status) {
        case 'NEW':
          classname = 'table-success';
          break;
        case 'DELETED':
          classname = 'table-danger';
          break;
        default:
          classname = '';
          break;
      }
      return classname;
    },
    savecsv() {
      const self = this;
      const api = new Apicall();
      self.success = null;
      self.error = null;
      self.showsavebtn = false;
      api.call('POST', 'api/holasun/savecsvfile', { id: this.$route.params.id, csvcontent: this.rawcsvcontent, csvaddoncontent: this.rawcsvcontentaddon }).then((response) => {
        if (response.status === 'OK') {
          self.success = response.msg;
        } else {
          self.showsavebtn = true;
          self.error = response.msg;
        }
        window.scrollTo(0, 0);
      });
    },
  },
};
</script>
